@import '../node_modules/react-flags-select/css/react-flags-select.css';
@import '../node_modules/react-phone-input-2/lib/style.css';
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';
@import '../node_modules/react-photo-view/dist/react-photo-view.css';

@font-face {
  font-family: 'amaranthbold';
  src: url('/fonts/amaranth/amaranth-bold-webfont.woff2') format('woff2'),
    url('/fonts/amaranth/amaranth-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'amaranthregular';
  src: url('/fonts/amaranth/amaranth-regular-webfont.woff2') format('woff2'),
    url('/fonts/amaranth/amaranth-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratextralight';
  src: url('/fonts/montserrat/montserrat-extralight-webfont.woff2')
      format('woff2'),
    url('/fonts/montserrat/montserrat-extralight-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratitalic';
  src: url('/fonts/montserrat/montserrat-italic-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratlight';
  src: url('/fonts/montserrat/montserrat-light-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratmedium';
  src: url('/fonts/montserrat/montserrat-medium-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratregular';
  src: url('/fonts/montserrat/montserrat-regular-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratsemibold';
  src: url('/fonts/montserrat/montserrat-semibold-webfont.woff2')
      format('woff2'),
    url('/fonts/montserrat/montserrat-semibold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratthin';
  src: url('/fonts/montserrat/montserrat-thin-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-thin-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratblack';
  src: url('/fonts/montserrat/montserrat-black-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratbold';
  src: url('/fonts/montserrat/montserrat-bold-webfont.woff2') format('woff2'),
    url('/fonts/montserrat/montserrat-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'montserratextrabold';
  src: url('/fonts/montserrat/montserrat-extrabold-webfont.woff2')
      format('woff2'),
    url('/fonts/montserrat/montserrat-extrabold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: 'montserratregular';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;
}

.input-number input::-webkit-outer-spin-button,
.input-number input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.phone-input-standard {
  margin-top: 11px !important;
}

.phone-input-standard .react-tel-input .form-control {
  width: 100%;
  border: 0;
  border-bottom: 1px solid #949494;
  border-radius: 0;
}

.phone-input-standard .react-tel-input .flag-dropdown {
  background: transparent;
  border: 0;
}

.phone-input .react-tel-input .form-control + div:before {
  display: none !important;
}

.phone-input-standard-border .react-tel-input .form-control {
  width: 100%;
  height: 39px;
}

.flag-select__options {
  right: 0;
}

.flag-select__btn:focus,
.mapboxgl-map .mapboxgl-canvas:focus {
  outline: none;
}

.mapboxgl-popup-content {
  border-radius: 12px;
  padding: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.4);
}

.mapboxgl-popup-close-button {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.break-all {
  word-break: break-all;
}

.text-white {
  color: #fff !important;
}

.text-green {
  color: #23ab23;
}

.text-red {
  color: #ff0068;
}

.text-grey {
  color: #827575;
}

.error {
  color: #f44336;
  margin: 4px 14px 0;
  font-size: 0.75rem;
  text-align: left;
  font-family: 'montserratregular';
  font-weight: 400;
  line-height: 1.66;
}

.icon-up-down {
  font-size: 1rem !important;
  vertical-align: middle;
  margin-right: 5px;
}

.align-center {
  vertical-align: middle;
}

.padding-page-full-height {
  padding: 20px 15px;
  min-height: calc(100vh - 80px);
}

.status-tag {
  border-radius: 16px;
  padding: 4px 10px;
  display: inline-block;
}

.status-ok {
  color: #fff !important;
  background-color: #23ab23;
}

.status-failed {
  color: #fff !important;
  background-color: #ff0068;
}

.status-normal {
  color: #fff !important;
  background-color: #f7a30b;
}

.status-expired {
  color: #fff !important;
  background-color: #a29b9b;
}

.status-normal-promo-code {
  background-color: #7825bd;
  color: #fff;
}

.status-tag-promo {
  border-radius: 12px;
  padding: 7px 10px;
  display: inline-block;
  min-width: 100px;
}

.status-ok-text {
  color: #23ab23;
}
.status-failed-text {
  color: #ff0068;
}
.status-expired-text {
  color: #a29b9b;
}
.status-progress-text {
  color: #f7a30b;
}

.circle-status-ok {
  background-color: #23ab23;
  box-shadow: rgb(35 171 35) 0px 0px 6px 1px;
}
.circle-status-failed {
  background-color: #ff0068;
  box-shadow: 0 0 6px 1px #ca0025;
}
.circle-status-progress {
  background-color: #f7a30b;
  box-shadow: #ffcf7b 0 0 6px 1px;
}
.circle-status-expired {
  background-color: #a29b9b;
  box-shadow: #aca7a7 0 0 6px 1px;
}

.circle-shadow {
  vertical-align: middle;
  display: inline-block;
  width: 8px;
  height: 8px;
  margin-right: 10px;
  border-radius: 50%;
}

.background-primary {
  background: rgb(84, 25, 132) !important;
}

.btn-white {
  border-radius: 20px !important;
  color: #fff !important;
  border-color: #fff !important;
  padding: 7px 30px !important;
}

.btn-rounded {
  border-radius: 20px !important;
  padding: 7px 30px !important;
}

.btn-rounded-style {
  border-radius: 12px !important;
  padding: 7px 16px !important;
}

.btn-green {
  background-color: rgb(66, 148, 108) !important;
  color: #fff !important;
}

.full-width {
  width: 100%;
}

.margin0 {
  margin: 0;
}

.list-background li:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

.disabled-flags button:after {
  display: none;
}

.tag-rounded {
  height: 20px;
  display: inline-block;
  font-size: 0.7rem;
  min-width: 20px;
  line-height: 22px;
  text-align: center;
  border-radius: 10px;
  background: #b1aeae;
  color: #fff;
}

.maxWidth93 {
  max-width: 93%;
}

.scroll-form {
  height: calc(100vh - 230px) !important;
}

.marginFiltersTable {
  margin: 20px 0 40px;
}

.fixed-bottom {
  position: fixed !important;
  bottom: 20px;
  left: 230px;
}

.inline-block {
  display: inline-block;
}

.line-height {
  line-height: 1.4 !important;
}

.red {
  color: red;
}

.font-size-1em {
  font-size: 1em !important;
}

.btn-link-table {
  color: #ff0068;
  font-size: 12px;
  margin: 0;
  background: transparent;
  border: 0;
  text-decoration: underline;
  cursor: pointer;
}

.btn-link-table:hover {
  opacity: 0.9;
}

.btn-link-table:focus {
  outline: none;
}

.swal2-container {
  z-index: 2000 !important;
}

.btn-underline {
  border-bottom: 1px solid #7825bd !important;
  border-radius: 0 !important;
  padding-bottom: 0 !important;
}

.hover-primary:hover {
  color: #7825bd;
}

#marker {
  cursor: pointer;
}

.marker-text {
  position: relative;
}

.marker-text span {
  color: white;
  position: absolute;
  left: 14px;
  top: 7px;
  font-size: 12px;
  font-weight: bold;
}

.marker-circle {
  position: relative;
  text-align: center;
}

.marker-circle span {
  color: white;
  position: absolute;
  top: 7px;
  left: 0;
  right: 0;
  margin: auto;
  font-size: 12px;
  font-weight: bold;
}

.marker-driver {
  position: relative;
  z-index: 1;
  display: inline-block;
}

.notification {
  width: 18px;
  height: 18px;
  background: #9666a2;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 5px;
}

.MuiPickersToolbarButton-toolbarBtn h3.MuiPickersToolbarText-toolbarTxt,
.MuiPickerDTToolbar-separator {
  font-size: 2.5rem !important;
}

.animation-background {
  background: linear-gradient(132deg, #ff0068bd, #7825bdb8, #a37ac5a3);
  background-size: 400% 400%;
  animation: Gradient 15s ease infinite;
  padding: 30px;
  color: white;
  border-radius: 5px;
  position: relative;
  margin-bottom: 10px;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.circle {
  position: absolute;
  top: 0;
  right: 100px;
  bottom: 0;
  left: auto;
  margin: auto;
  transform-origin: top left;
  transform: scale(0) rotate(0deg) translate(-50%, -50%);
  animation: cube 12s ease-in forwards infinite;
}

.booking-detail {
  padding: 15px 35px;
  background: linear-gradient(
    125deg,
    rgb(120, 37, 189) 20%,
    rgb(212, 54, 201) 80%
  );
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
}

.paper-booking {
  width: 85vw;
  margin: auto;
  background: #fafafa !important;
  border-radius: 10px !important;
  padding: 20px;
}

.booking-data {
  padding: 8px 15px;
  background-color: #fff;
  margin-bottom: 10px;
}

.booking-stops {
  width: 100%;
  margin-bottom: 0;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 14%) !important;
}

.booking-stops .MuiAccordionSummary-root {
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 14%) !important;
}

.booking-stops.MuiAccordion-root.Mui-expanded {
  margin-bottom: 0 !important;
}

.booking-stops .MuiAccordionSummary-content {
  margin: 7px 0 !important;
}

.booking-stops .MuiAccordionSummary-root {
  min-height: 48px !important;
}

.point-style {
  min-width: 24px;
  color: #fff;
  height: 24px;
  display: inline-block;
  font-size: 13px;
  text-align: center;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 14%);
  line-height: 23px;
  margin-right: 20px;
  border-radius: 50%;
  background-color: #9a68c2;
}

.point-style-finished {
  background-color: #93ca00;
}

.package-data p {
  font-size: 0.9rem;
}

.package-data b {
  font-size: 0.85rem;
  margin-left: 3px;
}

.btn-chat {
  position: fixed !important;
  right: 25px;
  bottom: 10px;
  z-index: 99999;
}

.marginBottom10 {
  margin-bottom: 10px !important;
}

.marginTop10 {
  margin-top: 10px !important;
}

.confirm-wait-driver {
  padding: 25px;
}

.confirm-wait-driver .container-info {
  max-width: 650px;
  display: inline-block;
  padding: 100px 20px 40px;
}

.confirm-wait-driver .wait-driver-text {
  margin-bottom: 35px;
  padding: 10px 50px 25px;
}

.confirm-wait-driver .wait-driver-btn {
  margin-right: 20px;
  margin-bottom: 10px;
}

.showCode {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  font-size: 0.92rem;
  text-align: left;
  padding: 20px 10px 10px;
}

.showCode-code {
  color: #7825bd;
}

.showCode-unasignables {
  color: #7825bd;
  font-family: 'amaranthregular';
  font-size: 1.9rem;
}

.showCode b {
  font-size: 0.87rem;
}

.container-unasignables {
  max-height: calc(100vh - 430px);
  overflow-y: scroll;
}

.primaryColor {
  color: #7825bd;
}

.status-tag-style {
  border-radius: 7px;
  padding: 4px 12px;
  display: inline-block;
}

.status-normal-style {
  color: #663d00 !important;
  background-color: #fff6e4;
}
.status-ok-style {
  color: #274c44 !important;
  background-color: #effaee;
}
.status-failed-style {
  color: #621b16 !important;
  background-color: #ffe9ee;
}

.status-active-style {
  color: rgb(93,48,130) !important;
  background-color: rgb(236,233,256);
}

.btn-light-primary {
  background-color: #d8cffb !important;
  color: #7825bd !important;
}

@keyframes cube {
  from {
    transform: scale(2) rotate(0deg) translate(-50%, -50%);
    opacity: 0;
  }
  to {
    transform: scale(5) rotate(960deg) translate(-50%, -50%);
    opacity: 0.5;
  }
}

.left-right-animation {
  animation: fadeIn 2.5s ease-in forwards,
    leftRightAnimation 500ms 5s ease-in forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-350px);
  }
  20%,
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes leftRightAnimation {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 600px) {
  .text-center-xs {
    text-align: center;
  }
  .hidden-xs {
    display: none;
  }
  .block-xs {
    display: block !important;
  }
  .full-width-xs {
    width: 100%;
  }
  .MuiTypography-caption {
    display: inline-block;
  }
  .line-height-xs {
    line-height: 1.4 !important;
  }
  .scroll-form {
    height: auto !important;
    min-height: calc(100vh - 280px);
  }
  .padding0-xs2 {
    padding: 0 !important;
  }
  .confirm-wait-driver .container-info {
    padding: 50px 2px 20px;
  }
  .confirm-wait-driver .wait-driver-text {
    padding: 0;
    text-align: justify;
  }
}

@media (max-width: 960px) {
  .text-center-sm {
    text-align: center;
  }
  .text-left-sm {
    text-align: left;
  }
  .hidden-sm {
    display: none;
  }
  .block-sm {
    display: block;
  }
  .padding0-xs {
    padding: 0 !important;
  }
  .padding-b-sm-5 {
    padding-bottom: 5px !important;
  }
  .map-container-responsive {
    height: 280px !important;
    top: 10px !important;
    margin: 0 10px;
  }
  .paper-booking {
    width: auto;
  }
  .modal-assign-promo-code .content-modal,
  .content-form-assign-code {
    padding: 5px 25px;
  }
  .modal-assign-promo-code .content-modal-form-divider {
    margin-bottom: 20px;
  }
  .text-right-sm {
    text-align: right;
  }
}

@media (min-width: 961px) {
  .modal-assign-promo-code .content-modal {
    padding: 10px 50px;
  }
  .modal-assign-promo-code .content-modal-form {
    margin-bottom: 40px;
  }
  .content-form-assign-code {
    padding: 0px 15px 30px;
  }
  .modal-assign-promo-code .content-modal-form-divider {
    margin-top: 10px;
  }
}

@media (min-width: 1500px) {
  .container-width-lg {
    max-width: 1280px !important;
  }
}

.tag-next_day {
  text-align: center;
  border-radius: 16px;
  padding: 2px 5px;
  display: inline-block;
  color: white;
  font-weight: bold;
  background: #a473cc;
  width: 70px;
}

.tag-rent {
  text-align: center;
  border-radius: 16px;
  padding: 2px 5px;
  display: inline-block;
  color: white;
  font-weight: bold;
  background: rgb(245, 65, 158);
  width: 70px;
}

.tag-express {
  text-align: center;
  border-radius: 16px;
  padding: 2px 5px;
  display: inline-block;
  color: white;
  font-weight: bold;
  background: rgb(245, 128, 65);
  width: 70px;
}

.tag-same_day {
  text-align: center;
  border-radius: 16px;
  padding: 2px 5px;
  display: inline-block;
  color: white;
  font-weight: bold;
  background: rgb(245, 191, 65);
  width: 70px;
}

.tag-scheduled {
  text-align: center;
  border-radius: 16px;
  padding: 2px 5px;
  display: inline-block;
  color: white;
  font-weight: bold;
  background: #93ca00;
  width: 70px;
}

.btn-yellow {
  background: rgb(245, 191, 65) !important;
  color: rgb(51, 51, 51) !important;
}

.divider-service-type span:after {
  content: ' / ';
}

.divider-service-type span:last-of-type:after {
  content: '';
}

.bg-grey-block {
  background-color: rgb(247, 247, 247);
  padding: 7px 20px;
}

.active-primary-btn,
.active-yellow-btn,
.active-red-btn {
  min-width: 42px !important;
}

.active-table-code .active-primary-btn,
.active-table-code .active-primary-btn:hover {
  background-color: #7825bd;
  color: white;
}

.active-table-code .active-yellow-btn,
.active-table-code .active-yellow-btn:hover {
  background: rgb(245, 191, 65);
}

.active-table-code .active-red-btn,
.active-table-code .active-red-btn:hover {
  background: #ff0068;
  color: white;
}

.disabled-table-code .active-primary-btn,
.disabled-table-code .active-yellow-btn,
.disabled-table-code .active-red-btn {
  cursor: default;
  pointer-events: none;
}

.background-gray-balance {
  background: linear-gradient(132deg, #706067bd, #685c72b8, #ac92c1a3);
  padding: 18px;
  color: #fff;
}